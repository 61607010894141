import { createStore } from 'vuex';
import auth from './modules/auth';
import technicage  from './modules/technicage';
import config  from './modules/config';
import timer  from './modules/timer';
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
    storage: window.sessionStorage
})

const store = createStore({
    modules: {
        auth,
        technicage,
        config,
        timer
    },
    plugins: [vuexLocal.plugin]
});

export default store;
