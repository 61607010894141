<template>
  <div>
    <div class="tabs">
      <button v-for="tab in tabHeaders" :key="tab.name" @click="selectedTab = tab.name; selectedCode = tab.code" :class="{ active: selectedTab === tab.name }">
        {{ tab.label }}
      </button>
    </div>
    <div class="tab-content">
      <component :is="selectedComponent" :status="selectedCode"></component>
    </div>
  </div>
</template>

<script>
  import TableTechnicage from '@/views/technicage/TableTechnicage.vue';

  export default {
    name: 'TabsContainer',
    components: {
      TableTechnicage
    },
    data() {
      return {
        selectedTab: '',
        selectedComponent: '',
        selectedCode: '',
      };
    },
    props: {
      tabHeaders: {
        type: Array,
        required: true
      },
    },
    mounted(){
      this.tabHeaders.find(tab => {
        if (tab.selected == true) {
          console.log(tab)
          this.selectedTab = tab.name;
          this.selectedComponent = tab.component;
          this.selectedCode = tab.code;
        }
      })
    }
  };
</script>

<style scoped>
  .tabs {
    display: flex;
    border-bottom: 1px solid #ccc;
  }
  .tabs button {
    padding: 10px;
    cursor: pointer;
    border: none;
    background: none;
    outline: none;
  }
  .tabs button.active {
    border-bottom: 2px solid #000;
  }
  .tab-content {
    padding: 20px;
  }
</style>
