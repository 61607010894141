<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <table class="table text-center " style="width:100%">
        <thead>
        <tr>
          <th v-for="header in headers" :key="header">{{ header }}</th>
        </tr>
        </thead>
        <tbody class="table-border-bottom-0" v-if="rows!= null && rows.length > 0">
        <tr v-for="(item, index) in rows" :key="index">
          <td>{{ moment(item.date_realisation).format('DD/MM/YYYY') }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.name_technician }}</td>
          <td>{{ item.name_biologist }}</td>
          <td>{{ item.name_laboratory }}</td>
          <td>{{ item.state.label }}</td>
          <td>
            <button class="btn btn btn-secondary" @click="goEdit(item)" v-if="accessEdit(item)">Editer</button>
          </td>
        </tr>
        </tbody>
        <tbody v-else>
        <tr>
          <td colspan="999" class="text-center">Aucune donnée présente</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

</template>

<script>
  import moment from "moment";
  import EditTechnicage from "@/views/technicage/EditTechnicage.vue";
  import {mapGetters, mapMutations} from "vuex";
  import TechnicageService from "@/services/technicage/technicage"

  export default {
    name: 'TableContainer',
    components: {EditTechnicage},
    computed: {

      ...mapGetters('auth', ['isBiologist']),
      moment() {
        return moment
      },

    },
    props: {
      headers: {
        type: Array,
        required: true
      },

      rows: {
        type: Array,
        required: true,
      }
    },
    methods: {
      ...mapMutations('technicage', ['setEditTechnicage']),
      goEdit(technicage){
        this.setEditTechnicage(technicage);
        TechnicageService.update_state( technicage.id, {state: "WIP", isBiologist: this.isBiologist}).then(() => {
          console.log('Etat du technicage changé');
        })
      },

      accessEdit(item){
        if (!this.isBiologist && (item.state.code == 'TOEDIT' || item.state.code == 'EDITTECH')){
          return true
        }
        if (this.isBiologist && (item.state.code == 'TOEDIT' || item.state.code == 'EDITBIO' || item.state.code == 'EDITTECH' || item.state.code == 'FINISH')){
          return true
        }
        return false;
      }
    },

  };
</script>

<style scoped>

</style>
