<template>
  <div>
    <table-container :headers="tableHeaders" :rows="technicages" v-if="!showSpinner"/>
    <spinner v-else></spinner>
  </div>
</template>
<script>
import TableContainer from "@/components/TableContainer.vue";
import {mapActions, mapGetters} from "vuex";
import Spinner from "@/components/Spinner.vue";

export default {
  name: "TableTechnicage",
  components: {Spinner, TableContainer},
  data() {
    return {
      tableHeaders: ['Date de réalisation', 'Nom du technicage', 'Nom du technicien', 'Nom du biologiste', 'Laboratoire', 'Etat', 'Action'],
      showSpinner: false
    };
  },
  props: ['status'],
  methods: {
    ...mapActions('technicage', ['fetch']),
  },

  watch: {
    status(newVal, oldVal){
      this.showSpinner = true;
      if (this.user != null){
        this.fetch({idUser: this.user.id, status: this.status}).then(() => {
          this.showSpinner = false;
        });
      }
    }
  },

  computed: {
    ...mapGetters('technicage', ['technicages']),
    ...mapGetters('auth', ['user']),
  },
  created(){
    this.showSpinner = true;
    if (this.user != null){
      this.fetch({idUser: this.user.id, status: this.status}).then(() => {
        this.showSpinner = false;
      });
    }
  }
}
</script>
<style scoped>

</style>
