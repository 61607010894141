
const state = {
    time: 0,
    timerRunning: false,
    intervalId: null
};

const mutations = {
    setTime(state, payload) {
        state.time = payload;
    },
    setTimerRunning(state, payload) {
        state.timerRunning = payload;
    },
    setIntervalId(state, payload) {
        state.intervalId = payload;
    }

};
const actions = {
    startTimer({ commit, state }) {
        if (!state.timerRunning) {
            const intervalId = setInterval(() => {
                commit('setTime', state.time + 1);
            }, 1000);
            commit('setIntervalId', intervalId);
            commit('setTimerRunning', true);
        }
    },
    stopTimer({ commit, state }) {
        if (state.timerRunning) {
            clearInterval(state.intervalId);
            commit('setIntervalId', null);
            commit('setTimerRunning', false);
        }
    },
    resetTimer({ commit }) {
        commit('setTime', 0);
    }

};
const getters = {
    time: state => state.time,
    timerRunning: state => state.timerRunning
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
