import { createRouter, createWebHistory } from 'vue-router';
import Home from './../views/Home.vue';
import Login from './../views/auth/Login.vue';
import Dashboard from './../views/Dashboard.vue';
import auth from './../store/modules/auth';
import technicage from './../store/modules/technicage';
import EditTechnicage from "@/views/technicage/EditTechnicage.vue";

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        //meta: { requiresAuth: true },
    },
    {
        path: '/technicage/edit/:id',
        name: 'EditTechnicage',
        component: EditTechnicage,
        meta: { requiresAuth: true },
        props: true
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!auth.getters.isAuthenticated && to.name !== "Login") {
            next({
                name: 'Login',
                query: { redirect: to.fullPath },
            });
        } else next();

    } else {
        next();
    }
});

export default router;
