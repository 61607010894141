import axios from "@/services/api";
import {useStore} from "vuex";

const state = {
    user: null,
    token: localStorage.getItem('token') || '',
    isBiologist: false
};

const mutations = {
    setUser(state, user) {
        state.user = user;
    },
    setToken(state, token) {
        state.token = token;
        localStorage.setItem('token', token);
    },
    setBiologist(state, isBiologist) {
        state.isBiologist = isBiologist;
    },
    logout(state) {
        state.user = null;
        state.token = '';
        state.refreshToken = '';
        state.isBiologist = false;
        localStorage.removeItem('token');
    },

};
const actions = {
    async login({ commit }, { email, password }) {
        try {
            await axios.post('/login', {
                email,
                password,
            }).then(data => {
                    commit('setToken', data.data.token);
                    if (data.data.token){
                        axios.get('users/me').then(
                            data => {
                                commit('setUser', data.data);
                                if (data.data.roles &&
                                    data.data.roles.includes('ROLE_BIOLOGIST'))
                                    commit('setBiologist', true);
                            });
                    }
            }

            );


        } catch (error) {
            console.error('Login failed', error);
        }
    },

    logout({ commit }) {
        commit('logout');
    },

};
const getters = {
    isAuthenticated: state => !!state.token,
    user: state => state.user,
    token: state => state.token,
    isBiologist: state => state.isBiologist
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
