import TechnicageService from '@/services/technicage/technicage'
import {useStore} from "vuex";

const state = {
    technicages: null,
    tests_technicage: null,
    edit_technicage: null
};

const mutations = {
    setListTechnicages: (state, technicages) => {
        state.technicages = technicages
    },
    setTestsTechnicage: (state, tests_technicages) => {
        state.tests_technicage = tests_technicages
    },
    setTest(state, dataTest){
        console.log(dataTest.test)
        state.tests_technicage[dataTest.indexTab] = dataTest.test;
    },
    setEditTechnicage: (state, edit_technicage) => {
        state.edit_technicage = edit_technicage
    },
};
const actions = {
    async fetch({ commit }, {idUser, status}) {
        try {
            const response = await TechnicageService.fetch(idUser, status);
            console.log(response)
            commit('setListTechnicages', response);
        } catch (error) {
            console.error(error);
        }
    },

    async fetch_tests({ commit }, { idTechnicage, status, isBiologist }) {
        try {
            const response = await TechnicageService.fetch_tests(idTechnicage, status, isBiologist);
            commit('setTestsTechnicage', response);
        } catch (error) {
            console.error(error);
        }
    },
};
const getters = {
    technicages: state => state.technicages,
    tests_technicage: state => state.tests_technicage,
    edit_technicage: state => state.edit_technicage
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
