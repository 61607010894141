import axios from 'axios';
import router from "@/router";
import store from "@/store";
import technicage from "@/store/modules/technicage";

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_KEY_RESULT_IDIR, // Replace with your API base URL
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
    }
});

apiClient.interceptors.request.use(config => {

    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// Response interceptor to handle 401 errors
apiClient.interceptors.response.use((response) => {
    return response;
},  error => {
    if (error.response && error.response.status === 401) {
        //Redirect to login or error page
        router.push('/');
    }
    return Promise.reject(error);
});


export default {
    get(resource) {
        return apiClient.get(resource);
    },
    post(resource, data, config) {
        return apiClient.post(resource, data, config);
    },
    patch(resource, data, config) {
        apiClient.headers = {'Content-Type': 'application/merge-patch+json'};
        return apiClient.patch(resource, data, config);
    },

};
