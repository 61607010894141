<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {mapActions, useStore} from "vuex";

export default {
  name: 'App',

};
</script>

<style>
/* Add global styles here */
nav {
  margin-bottom: 20px;
}
nav a {
  margin: 0 10px;
  text-decoration: none;
}
</style>
