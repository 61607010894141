<template>
  <div v-if="!showSpinner">
    <div class="row">
      <!-- Header Edit Technicage -->
      <div class="column w-75">
        <div class="card">
          <div class="row">
            <div class="col-md-6">
              <p><b><u>Date de création:</u></b> {{ moment(edit_technicage.createdAt).format('DD/MM/YYYY') }}</p>
            </div>
            <div class=" col-md-6">
              <p><b><u>Nom du technicage:</u></b> {{ edit_technicage.name}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <p><b><u>Date de réalisation:</u></b> {{ moment(edit_technicage.date_realisation).format('DD/MM/YYYY') }} </p>
            </div>
            <div class="col-md-6">
              <p><b><u>Nom du technicien:</u></b> {{ edit_technicage.name_technician}} </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <p><b><u>Statut:</u></b> {{ edit_technicage.status.label }}</p>
            </div>
            <div class="col-md-6">
              <p><b><u>Nom du biologiste:</u></b> {{ edit_technicage.name_biologist}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6">
              <p><b><u>Laboratoire:</u></b> {{ edit_technicage.name_laboratory}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <p><b><u>Commentaire technicien:</u></b></p>
              <p><textarea class="form-control" type="text" v-model="edit_technicage.commentaireTechnician" :disabled="isBiologist"></textarea></p>
              <button class="btn btn-info" @click="updateCommentaire" v-if="!isBiologist">Mettre à jour commentaire technicien</button>
              <br/><span style="margin-left: 5px" v-if="messageUpdateCommentaireTechnicien !==  ''"><small>{{ messageUpdateCommentaireTechnicien }}</small></span>
            </div>
            <div class="col-md-6">
              <p><b><u>Commentaire médecin biologiste:</u></b></p>
              <p><textarea class="form-control" type="text" v-model="edit_technicage.commentaireMedecin"  :disabled="!isBiologist"></textarea></p>
              <button class="btn btn-info" @click="updateCommentaire" v-if="isBiologist">Mettre à jour commentaire médecin biologiste</button>
              <br /><span style="margin-left: 5px" v-if="messageUpdateCommentaireMedecin !==  ''"><small>{{ messageUpdateCommentaireMedecin }}</small></span>
            </div>
          </div>
          <div class="row" >
            <div class="col-md-12">
              <button class="btn btn-info" @click="updateState" v-if="isBiologist">Renvoyer technicage au technicien</button>
              <span style="margin-left: 5px" v-if="messageReturnTechnician !==  ''"><small>{{ messageReturnTechnician }}</small></span>
            </div>
            <div class="col-md-6">
            </div>
          </div>
        </div>
      </div>
      <div class="column w-25">
        <div class="card text-center">
          <div class="row">
            <h3>R&#178;</h3>
            <p>{{ edit_technicage.r2}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3 " style="margin-top: 10px">
      <div class="column w-100">
        <div class="card" >
          <div class="row">
            <table-test-technicage @disable-validation="accessDeniedValidation" @enable-validation="accessAllowValidation" v-if="!isBiologist"></table-test-technicage>
            <stepper :step-headers="stepHeader" v-else></stepper>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <!-- TODO: To manage state-->
        <button  class="btn me-2 btn-primary" @click="goOut(true)">Retour la liste des technicages</button>
        <button  class="btn me-2 btn-primary" @click="validationTechnicage()" :disabled="disableValidation" v-if="!isBiologist">{{ displayValidation }}</button>
      </div>
    </div>
    <modal-time-logout v-if="timeLogout"></modal-time-logout>
  </div>
  <spinner v-else></spinner>


</template>

<script>
import Technicage from "@/views/technicage/Technicage.vue";
import Navbar from "@/components/Navbar.vue";
import TableTestTechnicage from "@/views/technicage/Tests/TableTestTechnicage.vue";
import {mapGetters, mapMutations, mapActions} from "vuex";
import moment from "moment";
import TechnicageService from "@/services/technicage/technicage"
import ModalTimeLogout from "@/views/auth/ModalTimeLogout.vue";
import Spinner from "@/components/Spinner.vue";
import Stepper from "@/components/Stepper.vue";

export default {

  name: "EditTechnicage",
  components: {Stepper, Spinner, ModalTimeLogout, TableTestTechnicage, Navbar, Technicage},
  props: ['id'],
  data(){
    return{
      stepHeader: [
        { name: 'ToValidate', label: 'Validation des test validés', code: 'VALIDATE', component: 'TableTestTechnicage', selected: true },
        { name: 'Anomaly', label: 'Validation des anomalies' , code: 'ANOMALY' , component: 'TableTestTechnicage', selected: false},
        { name: 'ValidateByBiologist', label: 'Confirmation ', code: 'VALBYBIOLOGIST', component: 'Confirmation', selected: false}
      ],
      editTechnicage: null,
      messageReturnTechnician: '',
      showSpinner: false,
      disableValidation: false,
      messageUpdateCommentaireTechnicien: '',
      messageUpdateCommentaireMedecin: '',
    }
  },
  setup(props) {
    return { ...props };
  },

  methods: {
    ...mapMutations('technicage', ['setEditTechnicage']),
    ...mapActions('timer', ['startTimer', 'stopTimer', 'resetTimer']),
    ...mapActions('technicage', ['fetch_tests']),

    accessDeniedValidation(){
      this.disableValidation = true;
    },

    accessAllowValidation(){
      this.disableValidation = false;
    },

    goOut(updateState){
      if (updateState){
        let stateCode = 'EDITTECH';
        if (this.isBiologist) stateCode = 'EDITBIO';
        TechnicageService.update_state(this.edit_technicage.id,{state: stateCode, isBiologist: this.isBiologist}).then(() => {
          this.setEditTechnicage(null);
          console.log('Etat du technicage changé');
        })
      }
      else this.setEditTechnicage(null);

    },

    async updateCommentaire(){
      let idTechnicage = this.edit_technicage.id;
      let formData = new FormData();
      formData.append('commentaireTechnician', this.edit_technicage.commentaireTechnician !== null ? this.edit_technicage.commentaireTechnician : '');
      formData.append('commentaireMedecin',  this.edit_technicage.commentaireMedecin !== null ? this.edit_technicage.commentaireMedecin : '');
      await TechnicageService.update_commentaire(idTechnicage, formData).then(() => {
        if (this.isBiologist) this.messageUpdateCommentaireMedecin = 'Le commentaire médecin biologiste a bien été mis à jour'
        else this.messageUpdateCommentaireTechnicien = 'Le commentaire technicien a bien été mis à jour'
      });
    },

    updateState(){
      TechnicageService.update_state(this.edit_technicage.id, {state: "EDITTECH", isBiologist: this.isBiologist}).then(() => {
        console.log('Etat du technicage changé');
        this.messageReturnTechnician = 'Le technicage a été renvoyé au technicien';
      })
    },

    async validationTechnicage(){
      if (this.goodForValidation()){
        let idTechnicage = this.edit_technicage.id;
        let status = 'VALBYTECH';
        let state = 'EDITBIO';
        if (this.isBiologist) status = 'VALBYBIOLOGIST';
        let formData = new FormData();
        formData.append('status', status);
        formData.append('state', state);
        formData.append('commentaireTechnician', this.edit_technicage.commentaireTechnician);
        formData.append('commentaireMedecin',  this.edit_technicage.commentaireMedecin);
        await TechnicageService.validation(idTechnicage, formData).then(() => {
          console.log('Technicage validé et envoyé chez le biologiste')
          this.goOut(false);
        })
      }
      else {
        this.goOut(true);
      }
    },

    goodForValidation(){
      let goodForValidation = true;
      if (this.tests_technicage != null){
        let i = 0;
        this.tests_technicage.find(item => {
          if (item != null){
            if (this.isBiologist){
              if (!(item.status != null && item.status.code === 'VALIDATE' || item.status != null && item.status.code === 'ANOMALY')) {
                console.log(item)

                goodForValidation = false;
              }
            }
            else if (item.statusTechnician != null && item.statusTechnician.code === 'WIP') {
              console.log(item)
              i++;
              goodForValidation = false;
            }
          }
        })
      }
      else{
        goodForValidation = false;
      }
      return goodForValidation;
    }
  },

  computed: {
    ...mapGetters('technicage', ['edit_technicage', "tests_technicage"]),
    ...mapGetters('auth', ['isBiologist']),
    ...mapGetters('timer', ['time', 'timerRunning']),
    moment() {
      return moment
    },

    displayValidation (){
      var goodForValidation = this.goodForValidation();
      if (goodForValidation) return 'Valider technicage et fermer';
      else return 'Enregistrer technicage et fermer';
    },

    timeLogout(){

      //Affichage de la modal de déconnexion 1 minute avant la fin
      if (this.time >= (process.env.VUE_APP_TIME_EDITION_TECHNICAGE - 60)){
        return true;
      }
      return false;
    }
  },

  mounted() {
    this.showSpinner = true;
    this.stopTimer();
    this.resetTimer();
    this.startTimer();
    this.fetch_tests({idTechnicage: this.edit_technicage.id}).then(() => {
      console.log(this.edit_technicage.id)
      this.showSpinner = false;
    });
  }
}
</script>

<style scoped>
  /* Float four columns side by side */
  .column {
    float: left;
    padding: 0 10px;
  }

  /* Remove extra left and right margins, due to padding */
  .row {margin: 0 -5px;}

  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  /* Responsive columns */
  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }
  }

  /* Style the counter cards */
  .card {
    padding: 16px;
    background-color: #ffffff;
  }

  textarea {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100px;
    white-space:nowrap;
  }
  .step {
    .bullet {
      border-radius: 50%;
      background-color: #26318d;
      color: white;
      font-weight: bold;
      width: 2rem;
      height: 2rem;
    }

    .title {
      font-size: small;
      color: #26318d;
      text-align: center;
      margin-top: 0.3rem;
    }
  }
</style>
