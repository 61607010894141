import api from "@/services/api";
import axios from "axios"

const state = {
    statusTechnicage: [],
    stateTechnicage: [],
    labos: [],
    anomalies: [],
    allBiologist: []
};

const mutations = {
    setStateTechnicage(state, stateTechnicage){
        state.stateTechnicage = stateTechnicage;
    },

    setStatusTechnicage(state, statusTechnicage){
        state.statusTechnicage = statusTechnicage;
    },

    setLabos(state, labos){
        state.labos = labos;
    },

    setAnomalies(state, anomalies){
        state.anomalies = anomalies;
    },

    setAllBiologist(state, allBiologist){
        state.allBiologist = allBiologist;
    },
};
const actions = {
    async initConfig({ commit }) {
        try {
            const stateResponse = await api.get('/state_technicages');
            const statusResponse = await api.get('/status_technicages');
            const anomalieResponse = await api.get('/anomalie_test_technicages');
            const biologistResponse = await api.get('/users?roles=ROLE_BIOLOGIST');

            commit('setStateTechnicage', stateResponse.data);
            commit('setStatusTechnicage', statusResponse.data);
            commit('setAnomalies', anomalieResponse.data);
            commit('setAllBiologist', biologistResponse.data);
            axios.defaults.headers.common['PASSRDVAPIKEY'] = process.env.VUE_APP_API_KEY_PASSRDV ;
            const laboResponse = await axios.get(process.env.VUE_APP_API_PASSRDV + '/entite');
            console.log(laboResponse.data.result);
            commit('setLabos', laboResponse.data.result);

        } catch (error) {
            console.error('Retrieve config error ', error);
        }
    },
};
const getters = {
    stateTechnicage: state => state.stateTechnicage,
    statusTechnicage: state => state.statusTechnicage,
    labos: state => state.labos,
    anomalies: state => state.anomalies,
    allBiologist: state => state.allBiologist,

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
