<template>
  <div>
    <form class="form-horizontal" v-if="!showSpinner">
      <div class="row">
        <div class="mb-3">
          <label class="form-label" for="dateCreation">Date de création du technicage</label>
          <input type="date" v-model="dateCreation" class="form-control" id="dateCreation" disabled >
        </div>
      </div>
      <div class="row">
        <div class="mb-3">
          <label class="form-label" for="dateRealisation">Date de réalisation du technicage</label>
          <input type="date" v-model="dateRealisation" class="form-control" id="dateRealisation">
        </div>
      </div>
      <div class="row">
        <div class="mb-3">
          <label class="form-label" for="nameTechnicage">Nom du technicage</label>
          <input type="text" v-model="nameTechnicage" class="form-control" id="nameTechnicage">
          <span v-if="errors.nameTechnicage" class="text-red">{{ errors.nameTechnicage }}</span>
        </div>
      </div>
      <div class="row">
        <div class="mb-3">
          <label class="form-label" for="nameTechnician">Nom du technicien</label>
          <input type="text" v-model="nameTechnician" class="form-control" id="nameTechnician" disabled>
        </div>
      </div>
      <div class="row">
        <div class="mb-3">
          <label class="form-label" for="nameBiologist">Nom du médecin biologiste</label>
          <select v-model="biologist" class="select2 form-select " id="laboratory">
            <option v-for="bio in allBiologist" :value="{id: bio.id, firstname: bio.firstname, lastname: bio.lastname}">
              {{ bio.lastname }} {{ bio.firstname }}
            </option>
          </select>
          <span v-if="errors.biologist" class="text-red">{{ errors.biologist }}</span>
        </div>
      </div>
      <div class="row">
        <div class="mb-3">
          <label class="form-label" for="laboratory">Laboratoire</label>
          <select v-model="laboratory" class="select2 form-select " id="laboratory">
            <option v-for="labo in labos" :value="{id: labo.id, name: labo.nom}">
              {{ labo.nom }}
            </option>
          </select>
          <span v-if="errors.laboratory" class="text-red">{{ errors.laboratory }}</span>
        </div>
      </div>
      <div class="row">
        <div class="mb-3">
          <label class="form-label" for="statut">Statut</label>
          <input type="text" v-model="status.label" class="form-control" id="statut" disabled>
        </div>
      </div>
      <div class="row">
        <div class="mb-3">
          <label class="form-label" for="etat">Etat</label>
          <input type="text" v-model="state.label" class="form-control" id="etat" disabled>
        </div>
      </div>
      <div class="row">
        <div class="mb-3">
          <label class="form-label" for="file">Fichier</label>
          <input type="file" @change="handleFileChange" class="form-control" id="file" accept=".xlsx">
          <span v-if="errors.file" class="text-red">{{ errors.file }}</span>
        </div>
      </div>
      <div class="form-group">
        <div class="col-md-12">
          <button id="save" type="submit" name="saveTechnicage" class="btn me-2 btn-primary float-right" @click.prevent = saveTechnicage(true)>Ajouter et éditer le technicage</button>
          <button id="save" type="submit" name="saveTechnicage" class="btn me-2 btn-primary float-right" @click.prevent = saveTechnicage(false)>Ajouter et retourner à la liste</button>
        </div>
      </div>
    </form>
    <spinner v-else></spinner>
  </div>
</template>

<script>
import api from '@/services/api';
import moment from 'moment';
import 'moment/locale/fr';
import {mapGetters, mapMutations, useStore} from "vuex";
import DropZone from 'dropzone-vue';
import TechnicageService from "@/services/technicage/technicage";
import Spinner from "@/components/Spinner.vue";

export default {

  name: "AddTechnicage",
  components: {Spinner, DropZone},
  data(){
    return{
      dateCreation: this.getTodayDate(),
      dateRealisation: this.getTodayDate(),
      nameTechnicage: '',
      idTechnician: 0,
      nameTechnician: '',
      biologist: null,
      laboratory: null,
      status: '',
      state: '',
      file: '',
      showSpinner: false,
      errors: {
        nameTechnicage: '',
        biologist: '',
        laboratory: '',
        file: ''
      }
    }
  },

  methods:{
    ...mapMutations('technicage', ['setEditTechnicage']),


    validateFormTechnicage(){

      //Name technicage
      if (this.nameTechnicage == '') {
        this.errors.nameTechnicage = 'Le nom du technicage est obligatoire';
      } else {
        this.errors.nameTechnicage = '';
      }

      if (this.biologist == null) {
        this.errors.biologist = 'La sélection du médecin biologiste est obligatoire';
      } else {
        this.errors.biologist = '';
      }

      if (this.laboratory == null) {
        this.errors.laboratory = 'La sélection du laboratoire est obligatoire';
      } else {
        this.errors.laboratory = '';
      }

      if (this.file == '') {
        this.errors.file = 'Le fichier est obligatoire';
      } else {
        this.errors.file = '';
      }
    },

    getTodayDate(){
      moment.locale('fr');
      // Get today's date in the format YYYY-MM-DD
      const today = moment(this.date).format('YYYY-MM-DD');
      return today;
    },
    handleFileChange(event) {
      this.file = event.target.files[0];
    },

    async saveTechnicage(goEdit) {
      this.validateFormTechnicage();
      if (!this.errors.nameTechnicage && !this.errors.biologist && !this.errors.laboratory && !this.errors.file){
        this.showSpinner = true;
        if (!this.file) {
          this.showSpinner = false;
          alert('Please select a file first!');
          return;
        }

        const formData = new FormData();
        formData.append('name', this.nameTechnicage);
        formData.append('date_realisation', this.dateRealisation);
        formData.append('id_technician', this.idTechnician);
        formData.append('name_technician', this.nameTechnician);
        formData.append('id_biologist', this.biologist.id);
        formData.append('name_biologist', this.biologist.firstname + ' ' + this.biologist.lastname);
        formData.append('id_laboratory', this.laboratory.id);
        formData.append('name_laboratory', this.laboratory.name);
        formData.append('status', this.status.code);
        formData.append('state', this.state.code);
        console.log(this.isBiologist);
        formData.append('isBiologist', this.isBiologist);
        //if return list => libéré technicage
        if (!goEdit) formData.append('state', 'TOEDIT');
        formData.append('file', this.file);

        try {
          const response = await api.post('/technicages/add', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          console.log('File and data uploaded successfully', response.data);
          this.showSpinner = false;
          if (goEdit) {
            this.setEditTechnicage(response.data.technicage);
          }
          this.$emit('close');
        } catch (error) {
          console.error('Error uploading file and data', error);
        }
      }
    }
  },

  computed: {
    ...mapGetters('config', ['stateTechnicage', 'statusTechnicage', 'labos', 'allBiologist']),
    ...mapGetters('auth', ['isBiologist']),
    formattedDateCreation() {
      moment.locale('fr');
      return moment(this.date).format('l');
    },

    formattedDateRealisation() {
      moment.locale('fr');
      return moment(this.date).format('l');
    },
  },

  mounted() {
    this.status = this.statusTechnicage.find(item => {
      return item.code == 'TOVALIDATE';
    })

    this.state = this.stateTechnicage.find(item => {
      return item.code == 'WIP';
    })

    const store = useStore();
    const user = store.getters['auth/user'];
    const isBiologist = store.getters['auth/isBiologist'];
    if (user != null){
      this.nameTechnician = user.firstname + ' ' + user.lastname;
      this.idTechnician = user.id;
      if (isBiologist) {
        this.idBiologist = user.id;
        this.nameBiologist = user.firstname + ' ' + user.lastname;
      }
    }
  }
}
</script>

<style scoped>

</style>
