<template>
  <!-- Navbar -->
  <nav
      class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
      id="layout-navbar"
  >

    <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
      <ul class="navbar-nav flex-row align-items-center ms-auto">
        <!-- User -->
        <div class="d-flex dropdown" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" >
          <div class="flex-shrink-0 me-3" >
            <div class="avatar avatar-online">
              <img src="@/assets/layout/img/avatars/1.png" alt class="w-px-40 h-auto rounded-circle" />
            </div>
          </div>
          <div class="flex-grow-1">
            <span class="fw-semibold d-block">{{user != null ? user.firstname + ' ' + user.lastname : ''}}</span>
            <small class="text-muted">{{ displayRole }}</small>
          </div>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li><a class="dropdown-item" @click="send_logout">Déconnexion</a></li>
          </ul>
        </div>
        <!--/ User -->
      </ul>
    </div>
  </nav>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import Navbar from "@/components/Navbar.vue";
import TechnicageService from "@/services/technicage/technicage";


export default {
  name: 'Navbar',
  components: {Navbar},
  computed: {
    ...mapGetters('auth', ['user', 'isBiologist']),
    ...mapGetters('technicage', ['edit_technicage']),
    displayRole(){
      if (this.user != null && this.user.roles){
        var roles = this.user.roles;
        if(roles.includes('ROLE_SUPER_ADMIN')) return 'Admin';
        else if (roles.includes('ROLE_BIOLOGIST')) return 'Médecin biologiste';
        else return 'Technicien'
      }
      return 'error';
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapMutations('technicage', ['setEditTechnicage']),
    send_logout() {
      if (this.edit_technicage != null){
        let stateCode = 'EDITTECH';
        if (this.isBiologist) stateCode = 'EDITBIO';
        TechnicageService.update_state(this.edit_technicage.id, {state: stateCode, isBiologist: this.isBiologist}).then(
          () => {
            this.setEditTechnicage(null);
            this.logout();
            this.$router.push('/');
          })
      }
      else{
        this.logout();
        this.$router.push('/');
      }

    },
  },

};
</script>


<style scoped>

</style>
