<template>
  <div class="container-xxl">
    <div class="authentication-wrapper authentication-basic container-p-y">
      <div class="authentication-inner">
        <!-- Register -->
        <div class="card">
          <div class="card-body">
            <!-- Logo -->
            <div class="app-brand justify-content-center">
              <a href="#" class="app-brand-link gap-2">
                  <span class="app-brand-logo demo">
                   <img width="209" src="./../../assets/img/logo-passrdv.png" alt=""/>
                  </span>
                <span class="app-brand-text demo text-body fw-bolder">PRO</span>
              </a>
            </div>
            <!-- /Logo -->
            <h4 class="mb-2">Bienvenue 👋</h4>
            <p class="mb-4">Sur la plateforme de de gestion des résultats des tests IDIR&#174;</p>
            <form id="formAuthentication" class="mb-3" action="" method="POST" @submit.prevent="send_login">
              <div class="mb-3">
                <label for="email" class="form-label">Email</label>
                <input
                    type="text"
                    class="form-control"
                    name="email" id="email"
                    placeholder="Saisissez votre email"
                    autofocus
                    required="required"
                    v-model="email"
                />
              </div>
              <div class="mb-3 form-password-toggle">
                <div class="d-flex justify-content-between">
                  <label class="form-label" for="password">Mot de passe</label>
                  <a href="">
                    <small>Mot de passe oublié ?</small>
                  </a>
                </div>
                <div class="input-group input-group-merge">
                  <input
                      type="password"
                      id="password"
                      class="form-control"
                      name="password"
                      placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                      aria-describedby="password"
                      required="required"
                      v-model="password"
                  />
                  <span class="input-group-text cursor-pointer"><i class="bx bx-hide"></i></span>
                </div>
              </div>
              <div class="mb-5">
                <button class="btn btn-primary d-grid w-100" type="submit">Connexion</button>
              </div>
            </form>
          </div>
        </div>
        <!-- /Register -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    ...mapActions('auth', ['login']),
    async send_login() {
      try {
        await this.login({ email: this.email, password: this.password })
        this.$router.push(this.$route.query.redirect || '/dashboard')

      } catch (error) {
        console.error('Failed to login', error);
      }
    },
  },
};
</script>
