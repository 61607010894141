<template>
  <div class="row" >
    <div class="card mb-4" v-if="edit_technicage == null">
      <div class="card-body">
        <div class="mb-3 d-flex">
          <a href="#" @click="showAddTechnicage = true" v-if="!showAddTechnicage" class="btn btn-info">Ajouter un technicage</a>
        </div>
        <add-technicage v-if="showAddTechnicage" @close="closeAddAnomalie"></add-technicage>
        <tabs-technicage v-else></tabs-technicage>
      </div>
    </div>
    <edit-technicage v-else></edit-technicage>
  </div>
</template>
<script>

import {mapActions, mapGetters} from "vuex";
import TabsTechnicage from "@/views/technicage/TabsTechnicage.vue";
import EditTechnicage from "@/views/technicage/EditTechnicage.vue";
import AddTechnicage from "@/views/technicage/AddTechnicage.vue";

export default {
  name: "Technicage",
  components: {
    AddTechnicage,
    EditTechnicage,
    TabsTechnicage
  },
  data(){
    return{
      showTabs: true,
      showAddTechnicage: false,
    }
  },
  computed: {
    ...mapGetters('technicage', ['edit_technicage']),

  },
  methods: {
    closeAddAnomalie(){
      this.showAddTechnicage = false
    }
  }

}
</script>
<style scoped>

</style>
