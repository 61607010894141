<template>
  <div class="bs-stepper" id="stepper">
    <div class="bs-stepper-header" role="tablist">
      <!-- your steps here -->
      <template v-for="(step, index) in stepHeaders" :key="step.name"  >
        <div class="step" :class="{ active: selectedStep === step.name }">
          <button  class="step-trigger" style="cursor: auto">
            <span class="bs-stepper-circle">{{ index + 1 }}</span>
            <span class="bs-stepper-label">{{ step.label }}</span>
          </button>
        </div>
        <div class="bs-stepper-line"></div>
      </template>
    </div>
    <div class="bs-stepper-content">
      <component :is="selectedComponent" :selectedStep="selectedStep" :status="selectedCode"></component>
      <button @click="nextStep()" class="btn btn-secondary float-end" style="margin-left: 5px" v-if="displayButtonNext">Suivant</button>
      <button @click="previousStep()" class="btn btn-secondary float-end" v-if="selectedIndex + 1 > 1">Précédent</button>
    </div>
  </div>
</template>

<script>

import TableTestTechnicage from '@/views/technicage/Tests/TableTestTechnicage.vue';
import Confirmation from '@/views/technicage/Confirmation.vue';
export default {
  name: "Stepper",
  components: {
    TableTestTechnicage,
    Confirmation
  },
  data(){
    return{
      selectedComponent: '',
      selectedStep: '',
      selectedCode: '',
      selectedIndex: 0
    }
  },
  props: {
    stepHeaders: {
      type: Array,
      required: true
    },
  },

  computed: {
    displayButtonNext(){
      console.log(this.stepHeaders.length )
      console.log(this.selectedIndex)
      if (this.stepHeaders.length != this.selectedIndex + 1){
        return true;
      }
    }
  },

  methods: {

    previousStep(){
      if (this.selectedIndex > 0){
        let stepItemSelected = this.stepHeaders[this.selectedIndex - 1];
        this.selectedStep = stepItemSelected.name;
        this.selectedComponent = stepItemSelected.component;
        this.selectedCode = stepItemSelected.code;
        this.selectedIndex = this.selectedIndex - 1;
      }
    },

    nextStep(){
      if (this.stepHeaders.length > this.selectedIndex){
        let stepItemSelected = this.stepHeaders[this.selectedIndex + 1];
        console.log(stepItemSelected)
        this.selectedStep = stepItemSelected.name;
        this.selectedComponent = stepItemSelected.component;
        this.selectedCode = stepItemSelected.code;
        this.selectedIndex = this.selectedIndex + 1;
      }
    }
  },

  mounted() {
    this.stepHeaders.find(step => {
      if (step.selected == true) {
        this.selectedStep = step.name;
        this.selectedComponent = step.component;
        this.selectedCode = step.code;
        this.selectedIndex = this.stepHeaders.findIndex(item => {
          return item.id === step.id;
        })
      }
    })
  }
}
</script>

<style scoped>

</style>
