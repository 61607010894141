<template>
  <tabs-container :tabHeaders="tabHeader"></tabs-container>
</template>

<script>
import TabsContainer from "@/components/TabsContainer.vue";
import EditTechnicage from "@/views/technicage/EditTechnicage.vue";
import {mapGetters} from "vuex";

export default {
  name: "TabsTechnicage",
  components: {EditTechnicage, TabsContainer},
  data(){
    return {
      tabHeadersTechnician: [
        { name: 'ToValidate', label: 'A valider', code: 'TOVALIDATE', component: 'TableTechnicage', selected: true },
        { name: 'Validate', label: 'Validé' , code: 'VALBYTECH' , component: 'TableTechnicage', selected: false},
        { name: 'ValidateByBiologist', label: 'Validé par le biologiste', code: 'VALBYBIOLOGIST', component: 'TableTechnicage', selected: false}
      ],

      tabHeadersBiologist: [
        { name: 'ToValidate', label: 'A traiter', code: 'TOVALIDATE', component: 'TableTechnicage', selected: false},
        { name: 'Validate', label: 'Validé par le technicien' , code: 'VALBYTECH' , component: 'TableTechnicage', selected: true},
        { name: 'ValidateByBiologist', label: 'Validé par le biologiste', code: 'VALBYBIOLOGIST', component: 'TableTechnicage', selected: false}
      ],
    }
  },

  computed :{
    ...mapGetters('auth', ['isBiologist']),
    tabHeader(){
      if (this.isBiologist) return this.tabHeadersBiologist;
      return this.tabHeadersTechnician;
    }
  }

}
</script>

<style scoped>

</style>
