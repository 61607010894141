<template>
  <div>
    <div class="row" v-if="status === 'VALIDATE' || !isBiologist">
      <div class="col-md-12">
        <button class="btn btn-secondary" @click="validationAllTestTechnicage(true)" style="margin-right: 5px;">Tout cocher validation</button>
        <button class="btn btn-secondary" @click="validationAllTestTechnicage(false)">Tout décocher validation</button>
      </div>

    </div>
    <div class="d-flex justify-content-between align-items-center" v-if="!showSpinner">
      <table class="table text-center " style="width:100%">
        <thead>
        <tr>
          <th v-for="header in headersToDisplay" :key="header">{{ header }}</th>
        </tr>
        </thead>
        <tbody class="table-border-bottom-0" v-if="listTests != null && listTests.length > 0">
          <tr v-for="(item, index) in listTests" :key="index">
            <!-- <td v-if="isBiologist">{{ displayStatusTechnician(item) }}</td> -->
            <td>
              <input v-if="isBiologist" type="checkbox" :disabled="item.anomalie != null" @click="validationTestTechnicage(item, false)" :checked="item.status && item.status.code === 'VALIDATE'">
              <input v-else type="checkbox" :disabled="item.anomalie != null" @click="validationTestTechnicage(item, false)" :checked="item.statusTechnician && item.statusTechnician.code === 'VALIDATE'" >

            </td>
            <td>{{ item.code_patient }}</td>
            <td>{{ item.bcaa }}</td>
            <td>
              <input v-if="isBiologist" type="checkbox" @click="manageAnomalie(item)" :checked="item.anomalie != null" :disabled="item.status && item.status.code === 'VALIDATE'">
              <input v-else type="checkbox" @click="manageAnomalie(item)" :checked="item.anomalie != null" :disabled="item.statusTechnician && item.statusTechnician.code === 'VALIDATE'">
            </td>
            <td>
              <button class="btn btn-info" @click="openEditAnomalie(item)" v-if="item.anomalie != null">Editer</button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="999" class="text-center">Aucune donnée présente</td>
          </tr>
        </tbody>
      </table>
      <div>
        <modal-anomalie v-if="showModalAnomalie" @close="closeModalAnomalie" :id-test="idTest"></modal-anomalie>
      </div>
    </div>
    <spinner v-else></spinner>
  </div>
</template>
<script>
import TableContainer from "@/components/TableContainer.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import Spinner from "@/components/Spinner.vue";
import ModalAnomalie from "@/views/technicage/Tests/ModalAnomalie.vue";
import api from "@/services/api";

export default {
  name: "TableTestTechnicage",
  components: {ModalAnomalie, Spinner, TableContainer},
  data() {
    return {
      tableHeadersBiologist: ['Validation', 'Code référence patient', 'Concentration en BCAA', 'Anomalie', 'Action'],
      tableHeadersTechnician: ['Validation', 'Code référence patient', 'Concentration en BCAA', 'Anomalie', 'Action'],
      showSpinner: true,
      showModalAnomalie: false,
      idTest: null
    };
  },
  props: ['status'],
  methods: {
    ...mapActions('technicage', ['fetch_tests']),
    ...mapMutations('technicage', ['setTest']),

    // --  START Manage anomalie
    async manageAnomalie(test){
      //if test has anomalie, remove anomalie
      if (test.anomalie != null){
        const dataAnomalieToRemove = {
          "anomalie": null,
          "commentaireTechnicienAnomalie": null,
          "commentaireMedecinAnomalie": null,
          "status": "WIP",
          "isBiologist" : this.isBiologist
        }
        this.$emit('disable-validation');
        const response = await api.patch('/tests_technicages/' + test.id + '/anomalie', dataAnomalieToRemove, {
          headers: {
            'Content-Type': 'application/merge-patch+json'
          }
        }).then(data => {
          console.log(data)
          this.afterCheck(data)
        });
        console.log('Anomalie retirée avec succès');
      }
      else{
        this.idTest = test.id;
        this.showModalAnomalie = true
      }
    },

    openEditAnomalie(test){
      console.log(test)
      this.idTest = test.id;
      this.showModalAnomalie = true
    },

    closeModalAnomalie(){
      this.showModalAnomalie = false;
      this.idTest = null;
      this.showSpinner = true;
      this.fetch_tests({idTechnicage: this.edit_technicage.id}).then(() => {
        this.showSpinner = false;
      });
    },

    // --  END Manage anomalie


    async validationAllTestTechnicage(allCheck){
      this.showSpinner = true;
      let idTechnicage = this.edit_technicage.id;
      let statusCode = 'VALIDATE'

      //Si uncheck
      if (!allCheck) statusCode = 'TOVALIDATE';

      let dataValidation = {
        "isBiologist" : this.isBiologist,
        "status" : statusCode
      }

      const response = await api.post('/technicages/' + idTechnicage + '/validation/all/test', dataValidation, {
        headers: {
          'Content-Type': 'application/merge-patch+json'
        }
      }).then(data => {
        console.log("Tous les test ont été validé");
        this.fetch_tests({idTechnicage: this.edit_technicage.id}).then(() => {
          this.showSpinner = false;
        });
      });
    },

    async validationTestTechnicage(test){
      const dataValidation = {
        isBiologist: this.isBiologist,
      };
      this.$emit('disable-validation');
      const response = await api.patch('/tests_technicages/' + test.id + '/validation', dataValidation, {
        headers: {
          'Content-Type': 'application/merge-patch+json'
        }
      }).then(data => {
        console.log("La validation du test a changé");
        this.afterCheck(data);
      });
    },

    afterCheck(data){
      this.$emit('enable-validation');
      let indexTabTest = this.tests_technicage.findIndex(item => {
        return item.id === data.data.id;
      });
      let dataTest = {indexTab: indexTabTest, test: data.data}
      this.setTest(dataTest);
    },
  },

  computed: {
    ...mapGetters('technicage', ['tests_technicage', 'edit_technicage']),
    ...mapGetters('auth', ['isBiologist']),
    headersToDisplay(){
      if (this.isBiologist) return this.tableHeadersBiologist;
      else return this.tableHeadersTechnician;
    },

    listTests(){
      console.log(this.tests_technicage);
      let tests = [];
      if(this.tests_technicage != null){
        this.tests_technicage.find(item => {
          if (this.status != null && this.isBiologist){
            if (item.statusTechnician != null && item.statusTechnician.code === this.status) tests.push(item);
          }
          else tests.push(item)
        })
      }
      console.log(tests);
      return tests;
    }
  },

  created(){
    console.log('test')
    this.showSpinner = false;
  }
}
</script>
<style scoped>

</style>
