<template>
  <div>
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" v-if="!showConfirmation">

          <div class="modal-header">
            <b>Gestion anomalie</b>
          </div>

          <div class="modal-body" >
            <div v-if="!showSpinner">
              <div class="row">
                <div class="mb-3">
                  <label  class="form-label">Anomalie*</label>
                  <select v-model="anomalie" class="select2 form-select " id="anomalie" >
                    <option :value="{value: '-1', checkAll: false}">Veuillez sélectionner une anomalie</option>
                    <option v-for="item in anomalies" :value="{value: item.label, checkAll: item.checkAll}" :selected="item.label === anomalie.value">
                      {{ item.label }}
                    </option>
                  </select>
                  <span class="text-red"  v-if="anomalie.checkAll"><small><b>En sélectionnant cette anomalie, tous les autres tests hériteront de cette même anomalie</b></small></span>
                  <span v-if="errors.anomalie" class="text-red">{{ errors.anomalie }}</span>
                </div>
              </div>
              <div class="row">
                <div class="mb-3">
                  <label class="form-label" for="statut">Commentaire technicien</label>
                  <textarea v-model="commentaireTechnicianAnomalie" class="form-control" id="statut"></textarea>
                </div>
              </div>
              <div class="row">
                <div class="mb-3">
                  <label class="form-label" for="statut">Commentaire médecin biologiste</label>
                  <textarea type="text" v-model="commentaireBiologistAnomalie" class="form-control" id="statut" :disabled="!isBiologist"></textarea>
                </div>
              </div>
            </div>
           <spinner v-else></spinner>
          </div>
          <div class="modal-footer" >
            <button class="btn btn-secondary m-2"@click="closeModal()">
              Fermer
            </button>
            <button class="btn btn-success "  @click="addAnomalie(false)">
              Ajouter anomalie
            </button>
          </div>
        </div>
        <div class="modal-container" v-else>
          <div class="modal-header">
            <b>Confirmation ajout anomalie</b>
          </div>

          <div class="modal-body text-center" >
            <spinner v-if="showSpinner"></spinner>
            <div v-else>
              <p>Tous les tests vont hérités de cette anomalie. Toutes les validations déjà effectuées vont être perdues</p>
              <p>Etes-vous sûr ? </p>
              <button class="btn btn-success" @click="addAnomalie(true)">Confirmer</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import api from "@/services/api"
import Spinner from "@/components/Spinner.vue";

export default {
  name: "ModalAnomalie",
  components: {Spinner},
  data(){
    return{
      showSpinner: false,
      showSpinnerConfirmation: false,
      anomalie:{
        value: '-1',
        checkAll: false
      } ,
      commentaireTechnicianAnomalie: '',
      commentaireBiologistAnomalie: '',
      errors: {
        anomalie: '',
      },
      showConfirmation: false
    }
  },

  props : ['idTest'],

  computed:{
    ...mapGetters('config', ['anomalies']),
    ...mapGetters('auth', ['isBiologist']),
    ...mapGetters('technicage', ['tests_technicage']),

  },

  methods:{
    ...mapMutations('technicage', ['setTestsTechnicage']),

    initValue(){
      this.anomalie = '-1';
      this.commentaireTechnicianAnomalie = '';
      this.commentaireBiologistAnomalie = '';
    },
    validateAnomalie(){
      if (this.anomalie.value == '-1') {
        this.errors.anomalie = 'L\'anomalie est requis.';
      } else {
        this.errors.anomalie = '';
      }
    },

    async addAnomalie(confirmation){
      this.validateAnomalie();
      if(this.anomalie.checkAll && !confirmation) this.showConfirmation = true;
      else{
        if(!this.errors.anomalie){
          this.showSpinner = true;
          const dataAnomalie = {
            "anomalie": this.anomalie.value,
            "commentaireTechnicienAnomalie": this.commentaireTechnicianAnomalie,
            "commentaireMedecinAnomalie": this.commentaireBiologistAnomalie,
            "status": "ANOMALY",
            "checkAll" : this.anomalie.checkAll,
            "isBiologist": this.isBiologist
          }

          const response = await api.patch('/tests_technicages/' + this.idTest + '/anomalie', dataAnomalie, {
            headers: {
              'Content-Type': 'application/merge-patch+json'
            }
          }).then(() =>{
            console.log('Anomalie enregistré avec succès');
            this.showSpinner = false;
            this.closeModal();
          });
        }
      }
    },

    closeModal(){
      this.initValue();
      this.$emit('close');
    }
  },

  created() {
    console.log('created');
    var test = this.tests_technicage.find(item => {
      return item.id === this.idTest;
    })
    if (test.anomalie){
      let anomalie = this.anomalies.find(item => {
        console.log(item.label)
        console.log(test.anomalie.label)
        return item.label === test.anomalie;
      });
      this.anomalie.value = test.anomalie;
      this.anomalie.checkAll = anomalie.checkAll;
      this.commentaireTechnicianAnomalie = test.commentaireTechnicienAnomalie;
      this.commentaireBiologistAnomalie = test.commentaireMedecinAnomalie;
    }

  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 75%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>
