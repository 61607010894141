<template>
  <div>
    <div v-if="!showSpinner">
      <navbar></navbar>
      <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="fw-bold py-3 mb-4">
              <span class="text-muted fw-light">Résultats test IDIR&#174; /</span> Technicage
            </h4>
          </div>
          <technicage></technicage>
        </div>
      </div>
    </div>
    <spinner v-else></spinner>
  </div>

</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import Navbar from "@/components/Navbar.vue";
import Technicage from "@/views/technicage/Technicage.vue";
import Spinner from "@/components/Spinner.vue";
  export default {
    name: 'Dashboard',
    components: {Spinner, Technicage, Navbar},
    data(){
      return{
        showSpinner: true
      }
    },
    computed: {
      ...mapGetters('auth', ['user']),
    },
    methods: {
      ...mapActions('config', ['initConfig']),
    },

    mounted() {
      this.showSpinner = true;
      this.initConfig().then(() => {
        this.showSpinner = false;
      })
    },

  };
</script>
